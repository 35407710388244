import React from 'react';
import { DatabaseSizePicker } from 'application/db-sizes';
import { ConfigureDatabaseFormData } from './form-data';
import { Database, DatabaseSize, Tier } from 'entities/database';
import { PlanType } from 'entities/tenant';
import { calcMonthlyCost } from 'application/db-sizes/capacities';
import formatDollars from 'utils/format-dollars';
import { Alert } from 'foundation';
import { useSession } from 'store';

interface ResizeDatabaseFormProps {
  data: ConfigureDatabaseFormData;
  onChange: (data: ConfigureDatabaseFormData) => any;
  sizes: DatabaseSize[];
  cloudProvider: string;
  initialSize: DatabaseSize;
  resizeThreshold?: number;
  database: Database;
}

export const validate = (data: ConfigureDatabaseFormData, initialSize?: DatabaseSize) => {
  if (!data.size) return false;
  if (data.size.memory !== initialSize?.memory) return data.confirmed;
  return true;
};

export const ResizeDatabaseForm = ({
  onChange,
  data,
  cloudProvider,
  sizes,
  initialSize,
  resizeThreshold,
  database,
}: ResizeDatabaseFormProps) => {
  const session = useSession();
  const handleSizeChange = (size: DatabaseSize) => onChange({ ...data, size });

  const showUnitPricing = session.planType === PlanType.SELF_SERVE;
  const showMonthlyCost =
    showUnitPricing && [Tier.PROFESSIONAL, Tier.ENTERPRISE].includes(database.Tier);

  return (
    <DatabaseSizePicker
      onChange={handleSizeChange}
      value={data.size}
      hidePricing={!showUnitPricing}
      options={sizes}
      cloudProvider={cloudProvider}
      selectedRegion={database.Region}
      hideMonthlyCost={!showMonthlyCost}
      selectedVersion={database.DesiredSettings.Version}
      initialSize={initialSize}
      resizeThreshold={resizeThreshold}
    />
  );
};

interface PriceDescriptionProps {
  planType: PlanType;
  tier: Tier;
  size: DatabaseSize;
}

export const PriceDescription = ({ planType, tier, size }: PriceDescriptionProps) => {
  if (planType === PlanType.ENTERPRISE) {
    return (
      <Alert
        data-testid="custom-pricing"
        description="Please refer to your contract for pricing."
        type="info"
      />
    );
  }

  if (tier === Tier.GDS) {
    const costPerHour = formatDollars(size.cost_per_hour);

    return (
      <Alert
        data-testid="aurads-pricing"
        description={`You will be charged ${costPerHour} per hour while an instance of this size is running.`}
        type="warning"
      />
    );
  }

  if ([Tier.PROFESSIONAL, Tier.MTE].includes(tier)) {
    const costPerHour = formatDollars(size.cost_per_hour);
    const costPerMonth = formatDollars(calcMonthlyCost(size.cost_per_hour));

    return (
      <Alert data-testid="auradb-pricing" type="warning">
        You will be charged {costPerHour} per hour while an instance of this size is running, and
        continuously running this database for 1 month will cost {costPerMonth} based on a 730 hour
        average month.
      </Alert>
    );
  }

  throw new Error(`No price description is defined for tier ${tier}`);
};
