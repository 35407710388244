import React, { useMemo } from 'react';

import { StickyActionsDataGrid, useDefaultTable, createColumnHelper, IconButton } from 'foundation';
import { PermissionTip } from 'components/application/permission-tip';
import { usePermissions } from 'store';
import { Action } from 'types/user';
import { CheckCircleIconSolid } from '@neo4j-ndl/react/icons';
import { LinkedTenant, SsoConfigFormAction, EnrichedSsoConfig } from '../types';
import { FormActionData } from '.';
import { Channel } from 'types/sso-config';

interface DataRowActions {
  edit?: React.ReactElement;
  delete?: React.ReactElement;
}

interface DataRow {
  ssoConfigId: string;
  displayName: string;
  idpType: string;
  clientId: string;
  organizationLoginMethod: string | React.JSX.Element;
  tenants: LinkedTenant[] | null;
  actions: DataRowActions;
}

interface SsoConfigTableProps {
  ssoConfigs: EnrichedSsoConfig[];
  setFormActionData: (data: FormActionData) => void;
}

const headerHelper = createColumnHelper<DataRow>();

const EditIcon = ({ allowEdit, hoverMessage, onClick }) => (
  <PermissionTip hasPermission={allowEdit}>
    <IconButton
      title={
        hoverMessage
          ? 'This sso configuration is managed by Neo4j customer support'
          : 'Edit SSO Config'
      }
      aria-label="Edit SSO Config"
      iconName="PencilIconOutline"
      disabled={!allowEdit}
      onClick={onClick}
      clean
    />
  </PermissionTip>
);

const DeleteIcon = ({ allowDelete, onClick }) => (
  <PermissionTip hasPermission={allowDelete}>
    <IconButton
      title="Delete SSO Config"
      aria-label="Delete SSO Config"
      iconName="TrashIconOutline"
      clean
      danger
      disabled={!allowDelete}
      onClick={onClick}
    />
  </PermissionTip>
);

const SsoConfigsTable = ({ ssoConfigs = [], setFormActionData }: SsoConfigTableProps) => {
  const { allow } = usePermissions();
  const allowEditSsoConfig = (ssoConfig: EnrichedSsoConfig) => {
    const isAllowed =
      allow(
        Action.UPDATE,
        `organizations/${ssoConfig.organizationId}/sso-configs/${ssoConfig.ssoConfigId}`
      ) && ssoConfig.channel !== Channel.FRONT;
    return isAllowed;
  };

  const allowDeleteSsoConfig = (ssoConfig: EnrichedSsoConfig) =>
    allow(
      Action.DELETE,
      `organizations/${ssoConfig.organizationId}/sso-configs/${ssoConfig.ssoConfigId}`
    );

  const data = useMemo(() => {
    const rows: DataRow[] = [];

    rows.push(
      ...ssoConfigs.map(
        (ssoConfig: EnrichedSsoConfig): DataRow => ({
          ssoConfigId: ssoConfig.ssoConfigId,
          displayName: ssoConfig.displayName,
          idpType: ssoConfig.idpType,
          clientId: ssoConfig.clientID,
          organizationLoginMethod: ssoConfig.organizationLoginMethod ? (
            <CheckCircleIconSolid className="n-size-token-7" color="green" />
          ) : (
            ''
          ),
          tenants: ssoConfig.linkedTenants,
          actions: {
            edit: (
              <div data-testid={`edit-${ssoConfig.ssoConfigId}`}>
                <EditIcon
                  allowEdit={allowEditSsoConfig(ssoConfig)}
                  onClick={() =>
                    setFormActionData({ action: SsoConfigFormAction.UPDATE, data: ssoConfig })
                  }
                  hoverMessage={ssoConfig.channel === Channel.FRONT}
                />
              </div>
            ),
            delete: (
              <div data-testid={`delete-${ssoConfig.ssoConfigId}`}>
                <DeleteIcon
                  allowDelete={allowDeleteSsoConfig(ssoConfig)}
                  onClick={() =>
                    setFormActionData({ action: SsoConfigFormAction.DELETE, data: ssoConfig })
                  }
                  data-testid={`delete-${ssoConfig.ssoConfigId}`}
                />
              </div>
            ),
          },
        })
      )
    );

    return rows;
  }, [ssoConfigs]);

  const columns = useMemo(
    () => [
      headerHelper.accessor('ssoConfigId', {
        header: 'ID',
        cell: c => c.getValue(),
      }),
      headerHelper.accessor('displayName', {
        header: 'Display Name',
        cell: c => c.getValue(),
      }),
      headerHelper.accessor('idpType', {
        header: 'IdP',
        cell: c => c.getValue(),
      }),
      headerHelper.accessor('clientId', {
        header: 'Client ID',
        cell: c => c.getValue(),
      }),
      headerHelper.accessor('organizationLoginMethod', {
        header: 'Org Login',
        cell: c => c.getValue(),
      }),
      headerHelper.accessor('tenants', {
        header: 'Projects',
        cell: c => (c.getValue() ?? []).map(s => `${s.tenantName} (${s.tenantId})`).join(', '),
      }),
      headerHelper.accessor('actions', {
        header: null,
        cell: c => (
          <span className="tw-flex tw-gap-1 tw-justify-end tw-flex-grow">
            {c.getValue().edit}
            {c.getValue().delete}
          </span>
        ),
        meta: {
          isStickyAction: true,
        },
      }),
    ],
    []
  );

  const table = useDefaultTable({ columns, data, state: {} });
  return <StickyActionsDataGrid tableInstance={table} data-testid="sso-config-table" />;
};

export default SsoConfigsTable;
