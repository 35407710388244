import React from 'react';

const ErrorPage = ({ eventId }: { eventId?: string }) => (
  <div className="tw-m-6 tw-flex tw-flex-col tw-gap-2">
    <h1>An error occurred</h1>
    <p>Try reloading the page, and if the problem persists please contact support.</p>
    {eventId && (
      <p>
        <strong>Error Event ID:</strong> {eventId}
      </p>
    )}
  </div>
);

export default ErrorPage;
