import React, { useMemo, useState, useEffect } from 'react';
import { Database } from 'entities/database';
import {
  StickyActionsDataGrid,
  Link,
  LoadingSpinner,
  createColumnHelper,
  useDefaultTable,
} from 'foundation';
import { DatabaseActionsMenu } from './db-actions-menu';
import TenantsResource from 'remote/resources/tenants';

interface Props {
  // Used when listing DBs in tenant
  databases?: Database[];
  // Used when looking up a single DBID
  database?: Database;
}

const columnHelper = createColumnHelper<Database>();

const formatSrePortalDomain = (environment: string, neo4jDomain: string) => {
  const env = environment ?? 'production';
  const domain = neo4jDomain ?? 'neo4j.io';
  const subdomain = env === 'production' ? 'sre' : `sre-${env}`;
  return `${subdomain}.${domain}`;
};

export const DatabasesTable = ({ databases, database }: Props) => {
  // Loaded only when looking up individual database
  const [tenantName, setTenantName] = useState(null);
  const [isLoadingTenant, setLoadingTenant] = useState(!!database);

  useEffect(() => {
    if (database) {
      setLoadingTenant(true);
      TenantsResource.get(database.NamespaceId)
        .then(tenant => {
          setTenantName(tenant.name);
        })
        .finally(() => setLoadingTenant(false));
    }
  }, [database]);

  const columns = useMemo(() => {
    const sreDomain = formatSrePortalDomain(process.env.ENVIRONMENT, process.env.NEO4J_DNS_DOMAIN);

    // Only used when looking up individual DB
    const tenantColumn = columnHelper.accessor('NamespaceId', {
      header: 'Project',
      minSize: 200,
      cell: cx => {
        const tenantId = cx.getValue();
        return (
          <div className="tw-w-full">
            <div className="tw-truncate">
              {isLoadingTenant ? (
                <LoadingSpinner className="tw-inline tw-mr-6" />
              ) : (
                <Link
                  href={`#admin/tenants/${tenantId}/users`}
                  title={`${tenantName} (${tenantId})`}
                >
                  {tenantName} (<code>{tenantId}</code>)
                </Link>
              )}
            </div>
          </div>
        );
      },
    });
    const extraColumns = database ? [tenantColumn] : [];

    return [
      columnHelper.accessor('DbId', {
        header: () => 'DBID',
        cell: cx => (
          <code>
            <a
              href={`https://${sreDomain}/databases/${cx.getValue()}/status`}
              target="_blank"
              rel="noreferrer"
            >
              {cx.getValue()}
            </a>
          </code>
        ),
      }),
      columnHelper.accessor('Name', {
        header: () => 'Name',
        minSize: 200,
        cell: cx => <div className="tw-w-full tw-text-left">{cx.getValue()}</div>,
      }),
      ...extraColumns,
      columnHelper.accessor('DatabaseStatus', {
        header: () => 'Status',
      }),
      columnHelper.accessor('TierDisplayName', {
        header: () => 'Tier',
        cell: cx => {
          let text = cx.getValue();
          const isTrial = !!cx.row.original.ProTrialEndTime;
          if (isTrial) {
            text = text.concat(' ', 'Trial');
          }
          return <div className="tw-w-full tw-text-left">{text}</div>;
        },
      }),
      columnHelper.accessor('DesiredSettings.Memory', {
        header: () => 'Memory',
        cell: cx => <code className="tw-w-full tw-text-left">{cx.getValue()}</code>,
      }),
      columnHelper.accessor('DesiredSettings.Version', {
        header: () => 'Version',
        cell: cx => <code className="tw-w-full tw-text-left">{cx.getValue()}</code>,
      }),
      columnHelper.accessor('ProTrialEndTime', {
        header: () => 'Pro Trial',
        cell: cx => {
          const proTrialEndTime = cx.getValue();
          if (proTrialEndTime) {
            return <span className="tw-w-full tw-text-left">true</span>;
          }
          return <span className="tw-w-full tw-text-left"></span>;
        },
      }),
      columnHelper.accessor('EncryptionKey', {
        header: () => 'CMEK',
        cell: cx => {
          const encryptionKey = cx.getValue();
          if (encryptionKey) {
            return <span className="tw-w-full tw-text-left">{encryptionKey.encryptionKeyRef}</span>;
          }
          return <span className="tw-w-full tw-text-left"></span>;
        },
      }),
      columnHelper.accessor('VectorOptimized', {
        header: () => 'Vector Optimized',
        cell: cx => {
          const vectorOptimized = cx.getValue();
          return (
            <span className="tw-w-full tw-text-left">{vectorOptimized ? 'True' : 'False'}</span>
          );
        },
      }),
      columnHelper.accessor('GDSPlugin', {
        header: () => 'GDS Plugin',
        cell: cx => {
          const gdsPlugin = cx.getValue();
          return <span className="tw-w-full tw-text-left">{gdsPlugin ? 'True' : 'False'}</span>;
        },
      }),
      columnHelper.accessor(db => db, {
        id: 'actions',
        size: 80,
        header: () => '',
        cell: cx => <DatabaseActionsMenu db={cx.getValue()} />,
        meta: {
          isStickyAction: true,
        },
      }),
    ];
  }, [database, isLoadingTenant, tenantName]);

  // Avoid changing data array on rerender as it causes the whole table
  // to be recalculated, which can lead to the page freezing up.
  // This is only applicable when rendering for a single database and it
  // would otherwise get wrapped up in a new array each time.
  const data = useMemo(() => (database ? [database] : databases), [database, databases]);

  const table = useDefaultTable({
    data: data,
    columns: columns,
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
    enableSorting: false,
  });

  return <StickyActionsDataGrid tableInstance={table} />;
};
