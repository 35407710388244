import { Button, IconButton } from 'components/foundation';
import React, { useState } from 'react';
import { useSession } from 'store';
import UpxBannerBackground from 'ui/icons/upx-banner.svg';
import UpxBannerBackgroundDark from 'ui/icons/upx-banner-dark.svg';
import { useDarkTheme } from 'utils/hooks';
import { renderToStaticMarkup } from 'react-dom/server';
import { formatUpxDomain } from 'components/utils';

export const UpxPreviewBanner = () => {
  const upxDomain = formatUpxDomain(process.env.ENVIRONMENT, process.env.NEO4J_DNS_DOMAIN);
  const darkMode = useDarkTheme();
  const session = useSession();
  const [hasBannerBeenDismissed, setHasBannerBeenDismissed] = useState<boolean>(false);

  const svgString = encodeURIComponent(
    renderToStaticMarkup(darkMode ? <UpxBannerBackgroundDark /> : <UpxBannerBackground />)
  );
  if (hasBannerBeenDismissed || !session.tenant) {
    return null;
  }
  return (
    <>
      <div
        className="tw-flex tw-relative tw-w-full tw-bg-palette-neutral-bg-weak tw-h-16 tw-min-h-16 tw-justify-center tw-border-b tw-border-palette-neutral-border-weak"
        style={{
          backgroundImage: `url("data:image/svg+xml,${svgString}")`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <div className="tw-font-syne tw-z-10 tw-self-center tw-flex tw-items-baseline tw-gap-2">
          New unified Aura Console is{' '}
          {session.featureToggles?.enable_default_upx_opt_in ? 'now live' : 'coming soon'}.{' '}
          <Button
            href={`https://${upxDomain}/projects/${session.tenant.id}/instances`}
            onClick={() => {
              localStorage.setItem(`upx-${session.userId}`, 'true');
            }}
            size="small"
          >
            Opt in now
          </Button>
        </div>
        <div className="tw-p-2 tw-absolute tw-right-0 tw-top-0 tw-bottom-0 tw-content-center">
          <IconButton
            iconName="XMarkIconOutline"
            clean
            aria-label="Dismiss new Aura Console banner"
            onClick={() => {
              setHasBannerBeenDismissed(true);
            }}
          />
        </div>
      </div>
    </>
  );
};
