import React, { useCallback, useState, useEffect, useMemo } from 'react';
import * as yup from 'yup';
import {
  DatabaseSize,
  findDatabaseSize,
  Tier,
  isSizeAvailableInRegion,
  isSizeAvailableForVersion,
  gibibytesStringToInt,
} from 'entities/database';
import {
  DatabaseNameInput,
  RegionSelect,
  VersionSelect,
  EncryptionKeySelect,
} from './form-essentials';
import CreateFormSizePicker from './form-size-picker';
import { validateYup, Validation } from 'utils/validation';
import { CreateDatabaseFormData, CreateDatabaseFormOptions } from './form-data';
import {
  EncryptionKeyRef,
  NEO4J_MANAGED_KEY,
  EncryptionKey,
  EncryptionKeyStatus,
} from 'types/encryption-keys';
import { useNeo4jVersionOptions, useRegionOptions } from './hooks';
import { Alert, Checkbox, Divider, Link, NewTabLink, Switch, Tip, Typography } from 'foundation';
import { availableRegionsForTier, getCloudProvider } from 'entities/tenant';
import './form-enterprise.css';
import { useDatabaseState, useSession } from 'store';
import { getDefaultVersion } from 'utils/neo4j-versions';
import { useCmeks } from 'remote/resources/encryption-keys';
import { CloneDatabaseChangeCmekWarning } from 'components/application/clone-db/index';

const schema = yup.object({
  name: DatabaseNameInput.yupValidator.label('Name'),
  region: yup
    .string()
    .required()
    .label('Region'),
  version: yup
    .string()
    .required()
    .label('Version'),
  size: yup.object({ memory: yup.string().required() }).required(),
  encryptionKeyRef: yup
    .string()
    .required()
    .label('Encryption Key'),
  vectorOptimized: yup.bool(),
  confirmed: yup
    .bool()
    .required('You must confirm that you understand the costs of creating a database')
    .isTrue('You must confirm that you understand the costs of creating a database'),
});

export const validate = (
  data: CreateDatabaseFormData,
  { onlyRequired }: { onlyRequired?: boolean } = {}
) => {
  return validateYup(schema, data, onlyRequired || false);
};

const getDefaultSize = (options: CreateDatabaseFormOptions) => {
  const preferredDefaultSize = '2GB';

  const availableSizes = options.databaseSizes[Tier.ENTERPRISE];
  const exactMatch = findDatabaseSize(availableSizes, preferredDefaultSize);

  return exactMatch || availableSizes[0];
};

export const defaults = (options: CreateDatabaseFormOptions): CreateDatabaseFormData => {
  const tier = Tier.ENTERPRISE;
  const cloudProvider = getCloudProvider(options.providerConfigs);

  return {
    name: '',
    region: availableRegionsForTier(options.providerConfigs, tier)[0]?.name,
    version: getDefaultVersion(options.providerConfigs, cloudProvider, Tier.ENTERPRISE),
    cloudProvider: getCloudProvider(options.providerConfigs),
    size: getDefaultSize(options),
    confirmed: undefined,
    tier,
    encryptionKeyRef: options?.database?.EncryptionKey?.encryptionKeyRef || NEO4J_MANAGED_KEY,
    isCloned: options.isCloned || false,
    vectorOptimized: false,
  };
};

interface Props {
  data: CreateDatabaseFormData;
  options: CreateDatabaseFormOptions;
  validation: Validation<CreateDatabaseFormData>;
  onChange: (data: CreateDatabaseFormData) => any;
  disabled?: boolean;
}

const EnterpriseFormFields = ({ data, onChange, validation, options, disabled }: Props) => {
  const session = useSession();
  const neo4jVersionOptions = useNeo4jVersionOptions(
    options.neo4jVersions,
    session.featureToggles['console-aura-latest']
  );
  const availableRegions = availableRegionsForTier(options.providerConfigs, data.tier);
  const regionOptions = useRegionOptions(availableRegions);
  const cloudProvider = getCloudProvider(options.providerConfigs);

  const handleSizeChange = useCallback(
    (size: DatabaseSize) => {
      const memoryInt = gibibytesStringToInt(size.memory);
      let { vectorOptimized } = data;
      if (memoryInt < 4) {
        vectorOptimized = false;
      }
      onChange({ ...data, size, vectorOptimized });
    },
    [data]
  );

  const handleConfirmedChange = ({ target: { checked } }) => {
    onChange({
      ...data,
      confirmed: showDifferentEncryptionWarning ? checked && differentEncryptionConfirmed : checked,
    });
    setIsDefaultConfirmed(checked);
  };
  const handleConfirmedDifferentEncryptionKeyChange = ({ target: { checked } }) => {
    onChange({ ...data, confirmed: isDefaultConfirmed && checked });
    setDifferentEncryptionConfirmed(checked);
  };
  const handleVersionChange = (version: string) => {
    if (!data.size) {
      onChange({ ...data, version });
    } else {
      const selectedSizeAllowed = isSizeAvailableForVersion(data.size, version, session.tenant);

      if (selectedSizeAllowed) {
        onChange({ ...data, version });
      } else {
        onChange({ ...data, version, size: undefined });
      }
    }
  };
  const handleDatabaseNameChange = (name: string) => onChange({ ...data, name });

  const handleVectorOptimizeChange = (value: boolean) => {
    onChange({ ...data, vectorOptimized: value });
  };

  const handleRegionChange = (region: string) => {
    if (!data.size) {
      onChange({ ...data, region });
    } else {
      const selectedSizeAllowed = isSizeAvailableInRegion(data.size, region, cloudProvider);
      const applicableKeysForRegion = readyKeys.filter(
        k => k.tier === data.tier && k.region === region
      );
      const initialEncryptionKey = options.database?.EncryptionKey?.encryptionKeyRef;
      const matchedInitialKey = applicableKeysForRegion.find(
        k => k.encryptionKeyRef === initialEncryptionKey
      )?.encryptionKeyRef;
      const secondaryKey = applicableKeysForRegion.find(
        k => k.encryptionKeyRef !== NEO4J_MANAGED_KEY
      )?.encryptionKeyRef;

      const selectedKey = matchedInitialKey || secondaryKey || NEO4J_MANAGED_KEY;

      if (selectedSizeAllowed) {
        onChange({ ...data, region, encryptionKeyRef: selectedKey });
      } else {
        onChange({ ...data, region, encryptionKeyRef: selectedKey, size: undefined });
      }
    }
  };
  const handleEncryptionKeyChange = (encryptionKeyRef: EncryptionKeyRef) => {
    onChange({ ...data, encryptionKeyRef });
  };

  const [showVersionWarning, setShowVersionWarning] = useState(false);
  const [showCmekWarning, setShowCmekWarning] = useState(false);
  const [showCmekOwnershipLossWarning, setShowCmekOwnershipLossWarning] = useState(false);
  const [isDefaultConfirmed, setIsDefaultConfirmed] = useState(false);
  const [differentEncryptionConfirmed, setDifferentEncryptionConfirmed] = useState(false);
  const [showDifferentEncryptionWarning, setShowDifferentEncryptionWarning] = useState(false);
  const { databases } = useDatabaseState();
  const cmeksQuery = useCmeks(options.tenant);

  const customKeys: EncryptionKey[] = cmeksQuery.isSuccess ? cmeksQuery.data : [];
  const readyKeys = customKeys.filter(k => k.status === EncryptionKeyStatus.READY);
  const applicableKeys = readyKeys.filter(k => k.tier === data.tier);

  useEffect(() => {
    if (!options.tenant.capabilities.cmek) return;
    const currentEncryptionKey = data.encryptionKeyRef;
    const initialEncryptionKey = options.database?.EncryptionKey?.encryptionKeyRef;
    const hasInitialEncryption = !!initialEncryptionKey;
    const hasDifferentEncryption = hasInitialEncryption
      ? currentEncryptionKey !== initialEncryptionKey
      : currentEncryptionKey !== NEO4J_MANAGED_KEY;
    const initialEncryptionKeyIsNeo4jManaged =
      !initialEncryptionKey || initialEncryptionKey === NEO4J_MANAGED_KEY;
    const initialEncryptionKeyIsCustomerManaged = !initialEncryptionKeyIsNeo4jManaged;

    if (!options.isCloned) {
      if (currentEncryptionKey !== NEO4J_MANAGED_KEY) {
        setShowCmekWarning(true);
      } else {
        setShowCmekWarning(false);
      }
      return;
    }

    if (
      (initialEncryptionKeyIsCustomerManaged && currentEncryptionKey === NEO4J_MANAGED_KEY) ||
      currentEncryptionKey === null
    ) {
      setShowCmekWarning(false);
      setShowDifferentEncryptionWarning(false);
      setShowCmekOwnershipLossWarning(true);
    } else if (hasDifferentEncryption) {
      setShowCmekWarning(false);
      setShowCmekOwnershipLossWarning(false);
      setShowDifferentEncryptionWarning(true);
    } else {
      setShowCmekWarning(currentEncryptionKey !== NEO4J_MANAGED_KEY);
      setShowCmekOwnershipLossWarning(false);
      setShowDifferentEncryptionWarning(false);
    }
    onChange({ ...data, confirmed: false });
    setIsDefaultConfirmed(false);
    setDifferentEncryptionConfirmed(false);
  }, [data.encryptionKeyRef]);

  const allowNeo4jManagedEncryptionKey = useMemo(() => {
    if (!options.tenant.capabilities.cmek) return true;
    if (!options.isCloned) return true;
    const initialEncryptionKey = options.database?.EncryptionKey?.encryptionKeyRef;
    if (!initialEncryptionKey) return true;
    if (initialEncryptionKey === NEO4J_MANAGED_KEY) return true;
  }, [options.tenant.capabilities.cmek, options.isCloned, options.database?.EncryptionKey]);

  useEffect(() => {
    const db = databases.some(database => database.DesiredSettings.Version === '4');
    setShowVersionWarning(db && data.version === 'Latest (Updates automatically)');
  }, [databases, data.version]);

  return (
    <>
      <CreateFormSizePicker
        options={options.databaseSizes[Tier.ENTERPRISE]}
        cloudProvider={cloudProvider}
        selectedRegion={data.region}
        hidePricing
        value={data.size}
        errorMessage={validation?.size?.message}
        selectedVersion={data.version}
        onChange={handleSizeChange}
      />

      <Divider hidden />

      <h4>Instance details</h4>
      <div
        className="form-enterprise instance-details tw-mt-4 tw-mb-6"
        data-testid="create-enterprise-db"
      >
        <div className="name">
          <DatabaseNameInput
            value={data.name}
            onChange={handleDatabaseNameChange}
            validationError={validation?.name?.message}
            disabled={disabled}
          />
        </div>
        <div className="region">
          <RegionSelect
            value={data.region}
            options={regionOptions}
            onChange={handleRegionChange}
            cloudProvider={cloudProvider}
            validationError={validation?.region?.message}
            disabled={disabled}
          />
        </div>
        {options.tenant.capabilities.cmek && (
          <div className="encryption-settings">
            <EncryptionKeySelect
              value={data.encryptionKeyRef}
              tier={data.tier}
              region={data.region}
              onChange={handleEncryptionKeyChange}
              validationError={validation?.encryptionKeyRef?.message}
              disabled={disabled}
              tenant={options.tenant}
              allowNeo4jManagedKey={allowNeo4jManagedEncryptionKey}
            />
            <p className="tw-font-light tw-text-xs tw-mt-2" data-testid="cmek-link">
              You can configure Customer Managed Keys{' '}
              <Link href="#customer-managed-keys">here</Link>.
            </p>
          </div>
        )}
        <div className="version">
          <VersionSelect
            value={data.version}
            options={neo4jVersionOptions}
            onChange={handleVersionChange}
            validationError={validation?.version?.message}
            disabled={disabled}
          />
        </div>
      </div>

      <Divider hidden />

      {session.tenant.capabilities.vector_optimized && (
        <div className="additional-settings">
          <h4 className="tw-mb-4 tw-mr-2 tw-inline-block tw-align-top tw-text-center">
            Additional Settings
          </h4>
          <div className="tw-flex tw-flex-row tw-items-center tw-justify-between tw-py-2">
            <div className="tw-flex tw-flex-col tw-gap-2">
              <Typography variant="subheading-medium">Vector-optimized configuration</Typography>
              <Typography
                variant="body-medium"
                as="div"
                className="tw-text-palette-neutral-text-weak"
              >
                Optimize Neo4j for GraphRAG, GenAI, and semantic search applications that index and
                search vector embeddings. See{' '}
                <NewTabLink href="https://neo4j.com/docs/aura/managing-instances/instance-details/#aura-vector-optimization">
                  documentation
                </NewTabLink>{' '}
                for details.
              </Typography>
            </div>
            <div>
              <Tip
                isDisabled={
                  (data.size ? gibibytesStringToInt(data.size.memory) >= 4 : true) &&
                  data.version === '5'
                }
              >
                <Tip.Trigger>
                  <Switch
                    aria-label="Toggle on Allocate vector index memory"
                    checked={data.vectorOptimized}
                    onChange={e => handleVectorOptimizeChange(e.target.checked)}
                    disabled={
                      (data.size ? gibibytesStringToInt(data.size.memory) < 4 : false) ||
                      data.version !== '5'
                    }
                  />
                </Tip.Trigger>
                <Tip.Content isPortaled={false}>
                  4GB memory is required for the vector index configuration
                </Tip.Content>
              </Tip>
            </div>
          </div>
        </div>
      )}

      {showVersionWarning && (
        <Alert
          type="warning"
          icon={true}
          description="The default Neo4j Version is now 5. Please check this is the desired version before continuing."
          className="tw-mb-6"
        />
      )}
      {options.tenant.capabilities.cmek && showCmekWarning && (
        <Alert
          type="warning"
          icon={true}
          description="By encrypting this instance with a Customer Managed Key, you take full responsibility for the management and security of the key. Neo4j cannot recover your data if the key is lost or invalid. There is a risk of permanent data loss."
        />
      )}
      {options.tenant.capabilities.cmek && showCmekOwnershipLossWarning && (
        <Alert
          type="warning"
          icon={true}
          description="Cloning to the selected region is not possible. There are no Customer Managed Keys in the selected region and cloning an encrypted instance to an instance with Neo4j Managed Key is not possible."
        />
      )}
      {showDifferentEncryptionWarning && (
        <Alert
          type="warning"
          icon={true}
          description={
            <div className="tw-pb-1">
              <CloneDatabaseChangeCmekWarning
                keys={applicableKeys}
                encryptionKey={data.encryptionKeyRef}
              />
              <div className="tw-my-2 tw-ml-3">
                <Checkbox
                  label="I understand"
                  checked={differentEncryptionConfirmed}
                  onChange={handleConfirmedDifferentEncryptionKeyChange}
                  disabled={disabled}
                  data-testid="neo4j-encryption-confirmation-check"
                />
              </div>
            </div>
          }
          className="tw-mb-6"
        />
      )}
      <div className="tw-mx-auto console-w-fit tw-mt-6">
        <p className="tw-font-light" data-testid="custom-pricing">
          Please refer to your contract for pricing.
        </p>
        <div className="tw-mx-auto console-w-fit tw-mt-4">
          <Checkbox
            label="I understand"
            checked={isDefaultConfirmed}
            onChange={handleConfirmedChange}
            disabled={disabled}
            data-testid="pricing-confirmation-check"
          />
          {validation?.confirmed?.message && (
            <Alert type="danger" className="tw-mt-2">
              {validation?.confirmed?.message}
            </Alert>
          )}
        </div>
      </div>
    </>
  );
};

export default EnterpriseFormFields;
