import { Database } from 'entities/database';
import React, { SyntheticEvent, useState } from 'react';
import { CustomEndpoint } from 'types/custom-endpoints';
import customEndpointResources from 'remote/resources/custom-endpoints';
import { Alert, Button, Checkbox, Dialog, TextInput, Tip } from 'components/foundation';
import { customEndpointURI } from 'entities/custom-endpoints';
import logger from 'logger';
import { useDefaultErrorHandler } from 'remote/error-handler';

type Props = {
  onClose: () => void;
  database?: Database;
  endpoint: CustomEndpoint;
  refetch: () => void;
};

export const DeleteCustomEndpointDialog = ({ onClose, database, endpoint, refetch }: Props) => {
  const [hasConfirmed, setHasConfirmed] = useState(false);
  const defaultErrorHandler = useDefaultErrorHandler();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();

    if (!hasConfirmed) {
      return;
    }

    setLoading(true);

    customEndpointResources
      .destroy(endpoint.id)
      .then(() => {
        refetch();
        onClose();
      })
      .catch(e => {
        logger.error('Failed to delete custom endpoint', e);
        defaultErrorHandler(e);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Dialog open onClose={onClose}>
      <Dialog.Header>Delete custom endpoint</Dialog.Header>
      <form onSubmit={handleSubmit}>
        <Dialog.Content className="tw-flex tw-flex-col tw-gap-5">
          <div className="tw-flex tw-flex-col tw-gap-4">
            <TextInput label="Custom endpoint" value={customEndpointURI(endpoint)} readOnly fluid />
            {database && (
              <TextInput label="Assigned instance" value={database?.Name} readOnly fluid />
            )}
          </div>
          {database ? (
            <Alert
              type="warning"
              description="Deleting this custom endpoint without updating your application may cause disruptions requiring manual resolution. Customer Support will be unable to assist with resulting issues."
              icon
            />
          ) : (
            <Alert
              type="info"
              description="This custom endpoint is not assigned to an instance, so deleting it should not cause any disruptions. Please note that the URL cannot be reused for any new custom endpoint."
              icon
            />
          )}
        </Dialog.Content>
        <Dialog.Actions className="tw-flex tw-justify-between tw-items-center">
          <Checkbox
            checked={hasConfirmed}
            onChange={e => setHasConfirmed(e.target.checked)}
            label="I understand"
            data-testid="confirm-checkbox"
          />
          <div className="tw-flex tw-gap-4 ">
            <Button color="neutral" fill="outlined" onClick={onClose} data-testid="cancel-button">
              Cancel
            </Button>
            <Tip isDisabled={hasConfirmed} allowedPlacements={['top']}>
              <Tip.Trigger>
                <Button
                  color="danger"
                  type="submit"
                  loading={loading}
                  disabled={!hasConfirmed}
                  data-testid="delete-button"
                >
                  Delete
                </Button>
              </Tip.Trigger>
              <Tip.Content isPortaled={false} style={{ width: 200, position: 'fixed' }}>
                <Tip.Body>
                  You need to confirm that you understand the consequences of deleting a custom
                  endpoint
                </Tip.Body>
              </Tip.Content>
            </Tip>
          </div>
        </Dialog.Actions>
      </form>
    </Dialog>
  );
};
