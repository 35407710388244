import React from 'react';
import { Alert, Switch } from 'foundation';

import {
  CentralityAndImportance,
  PathfindingAndSearch,
  CommunityDetection,
  NodeEmbedding,
  Similarity,
  SupervisedMachineLearning,
} from './algorithm-category-cards';

interface Props {
  categories: string[];
  unsure: boolean;
  errorMessage?: string;
  onChange: (categories: string[], unsure: boolean) => void;
}

const AlgorithmCategoryPicker = ({ categories, unsure, errorMessage, onChange }: Props) => {
  const handleCategorySelect = category => {
    let newCategories;
    if (categories.includes(category)) {
      newCategories = categories.filter(cat => cat !== category);
    } else {
      newCategories = [...categories, category];
    }

    onChange(newCategories, false);
  };

  const toggleNotSure = ({ target: { checked } }) => {
    onChange([], checked);
  };

  return (
    <>
      <div className="tw-mb-6">
        <Switch
          checked={unsure}
          onChange={toggleNotSure}
          label="I'm not sure which algorithms to use"
          data-testid="im-not-sure"
        />
      </div>
      <div
        className="tw-grid tw-gap-6 tw-auto-rows-fr"
        style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))' }}
      >
        <CentralityAndImportance
          testid="centrality"
          selected={categories.includes('centrality')}
          onClick={() => handleCategorySelect('centrality')}
          disabled={unsure}
        />
        <CommunityDetection
          testid="community-detection"
          selected={categories.includes('community-detection')}
          onClick={() => handleCategorySelect('community-detection')}
          disabled={unsure}
        />
        <Similarity
          testid="similarity"
          selected={categories.includes('similarity')}
          onClick={() => handleCategorySelect('similarity')}
          disabled={unsure}
        />
        <NodeEmbedding
          testid="node-embedding"
          selected={categories.includes('node-embedding')}
          onClick={() => handleCategorySelect('node-embedding')}
          disabled={unsure}
        />
        <SupervisedMachineLearning
          testid="machine-learning"
          selected={categories.includes('machine-learning')}
          onClick={() => handleCategorySelect('machine-learning')}
          disabled={unsure}
        />
        <PathfindingAndSearch
          testid="pathfinding-and-search"
          selected={categories.includes('path-finding')}
          onClick={() => handleCategorySelect('path-finding')}
          disabled={unsure}
        />
      </div>
      {errorMessage && (
        <Alert type="danger" data-testid="category-error-msg" className="tw-mt-2">
          <p>{errorMessage}</p>
        </Alert>
      )}
    </>
  );
};

export default AlgorithmCategoryPicker;
