import React, { useState } from 'react';
import { Dialog, Button, Alert, Form } from 'foundation';
import { Validation } from 'utils/validation';
import SsoConfigResource from 'remote/resources/sso-configs';
import NamespacesResource from 'remote/resources/namespaces';
import { validateSsoConfig } from './sso-config-validation';
import SsoConfigFormFields from './sso-config-form-fields';
import { EnrichedSsoConfig, LinkedTenant, SsoConfigFormAction, SsoConfigFormData } from '../types';
import { SsoConfig } from 'types/sso-config';
import { ApiClientRequestError } from 'remote/api-client/api-client-error';

export interface EditSsoConfigModalProps {
  open: boolean;
  closeModal(): void;
  saveAndClose(config: SsoConfig): void;
  config: EnrichedSsoConfig;
}

const defaults = (config: EnrichedSsoConfig): SsoConfigFormData => {
  return { ...config, clientSecret: undefined };
};

const EditSsoConfigModalGuard = ({ closeModal, saveAndClose, config, component: Component }) => {
  return (
    <Component
      open={true}
      closeModal={closeModal}
      saveAndClose={saveAndClose}
      config={{
        ...config,
        originalLinkedTenants: config.linkedTenants,
        linkedTenants: config.linkedTenants,
        tenantLoginMethod: config.linkedTenants?.length > 0,
      }}
    />
  );
};

const EditSsoConfigModalBase = ({
  open,
  closeModal,
  saveAndClose,
  config,
}: EditSsoConfigModalProps) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [ssoConfig, setSsoConfig] = useState<SsoConfigFormData>(defaults(config));
  const [validation, setValidation] = useState<Validation<any>>(null);
  const [hasFailedValidation, setHasFailedValidation] = useState(false);

  const handleClose = () => {
    setSsoConfig(null);
    setErrorMessage(null);
    setValidation(null);
    setHasFailedValidation(false);
    closeModal();
  };

  const updateSsoConfigIdWithHandling = async (tenantId, ssoConfigId, action) => {
    try {
      if (action === 'delete') {
        await NamespacesResource.unlinkSsoConfigId(tenantId, ssoConfigId);
        return { tenantId, ssoConfigId, success: true };
      }
      if (action === 'update') {
        await NamespacesResource.linkSsoConfigId(tenantId, ssoConfigId);
        return { tenantId, ssoConfigId, success: true };
      }
    } catch (error) {
      return { tenantId, ssoConfigId, success: false, error: error.message };
    }
  };

  const handleUpdateTenantsWithSso = async (
    linkedTenants: LinkedTenant[],
    originalLinkedTenants: LinkedTenant[]
  ) => {
    const addedTenants = linkedTenants.filter(
      ({ tenantId }) => !originalLinkedTenants.some(tenant => tenant.tenantId === tenantId)
    );
    const deletedTenants = originalLinkedTenants.filter(
      ({ tenantId }) => !linkedTenants.some(tenant => tenant.tenantId === tenantId)
    );

    const updatePromises = addedTenants.map(({ tenantId }) =>
      updateSsoConfigIdWithHandling(tenantId, config.ssoConfigId, 'update')
    );
    const deletePromises = deletedTenants.map(({ tenantId }) =>
      updateSsoConfigIdWithHandling(tenantId, config.ssoConfigId, 'delete')
    );

    try {
      const results = await Promise.all([...updatePromises, ...deletePromises]);
      const failures = results.filter(result => !result.success);
      if (failures.length > 0) {
        setErrorMessage(
          `Failed to update SSO Config ID for projects: ${failures
            .map(failure => failure.tenantId)
            .join(', ')}`
        );
      }
    } catch (error) {
      setErrorMessage('Unknown failure when updating SSO Config ID for project');
    }
  };

  const handleSubmit = async () => {
    setErrorMessage(null);
    const newValidation = validateSsoConfig(ssoConfig);
    if (newValidation) {
      setValidation(newValidation);
      setHasFailedValidation(true);
      return;
    }
    setLoading(true);
    try {
      const {
        tenantLoginMethod,
        originalLinkedTenants,
        linkedTenants,
        ...dataToSubmit
      } = ssoConfig;
      const updatedConfig = await SsoConfigResource.update(config.ssoConfigId, dataToSubmit);
      await handleUpdateTenantsWithSso(
        tenantLoginMethod ? linkedTenants : [],
        originalLinkedTenants
      );

      setLoading(false);
      saveAndClose(updatedConfig);
    } catch (err) {
      if (err instanceof ApiClientRequestError) {
        setErrorMessage(err.responseMessage || 'Unknown error.');
      } else {
        setErrorMessage(err.toString());
      }
      setLoading(false);
    }
  };

  const maybeValidate = (data: EnrichedSsoConfig) => {
    if (hasFailedValidation) {
      setValidation(validateSsoConfig(data));
    }
  };

  const handleChange = (data: EnrichedSsoConfig) => {
    setSsoConfig(data);
    maybeValidate(data);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      modalProps={{ 'data-testid': 'edit-sso-config-modal' }}
    >
      <Dialog.Header>Edit SSO Configuration</Dialog.Header>
      <Dialog.Description>
        <div className="n-body-large">Edit SSO Config {`"${config.ssoConfigId}"`}</div>
        <p>This will not remove SSO from instances which were created while SSO was configured.</p>
      </Dialog.Description>
      <Dialog.Content data-testid="edit-sso-config-modal-content">
        <Form onSubmit={handleSubmit} id="update-sso-config-form">
          <SsoConfigFormFields
            config={ssoConfig}
            onChange={handleChange}
            validation={validation}
            formAction={SsoConfigFormAction.UPDATE}
            organizationId={ssoConfig.organizationId}
          />
        </Form>

        {errorMessage && (
          <Alert
            className="tw-mt-2"
            type="danger"
            data-testid="edit-sso-config-error-message"
            description={`There was an error editing the SSO Config: ${errorMessage}`}
          />
        )}
      </Dialog.Content>
      <Dialog.Actions>
        <Button
          onClick={handleClose}
          color="neutral"
          fill="outlined"
          disabled={loading}
          data-testid="discard-sso-config-changes-button"
        >
          Discard
        </Button>
        <Button
          loading={loading}
          form="update-sso-config-form"
          data-testid="update-sso-config-button"
          onClick={handleSubmit}
        >
          Save Changes
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};

const WrappedEditSsoConfigModalGuard = ({ closeModal, saveAndClose, formAction }) => {
  return (
    <EditSsoConfigModalGuard
      closeModal={closeModal}
      saveAndClose={saveAndClose}
      config={formAction.data}
      component={EditSsoConfigModalBase}
    />
  );
};

export default WrappedEditSsoConfigModalGuard;
