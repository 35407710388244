import React, { HTMLAttributes } from 'react';
import Icon from 'components/ui/icons';
import cx from 'classnames';

import './accordion.css';
interface AccordionProps extends HTMLAttributes<HTMLDivElement> {
  position?: 'left' | 'right';
  header: React.ReactNode | string;
  open: boolean;
  onOpen: () => any;
  disabled?: boolean;
  'data-testid'?: string;
}

export const Accordion = ({
  header,
  position = 'right',
  open,
  onOpen,
  children,
  className,
  disabled,
  'data-testid': dataTestId,
  ...rest
}: AccordionProps) => {
  const accordionClasses = cx('console-accordion tw-flex tw-flex-col', className);
  const titleClasses = cx(
    'console-accordion-title tw-flex tw-max-h-full tw-mb-4 n-subheading-medium tw-flex-row tw-items-center tw-select-none tw-h-12',
    {
      disabled,
    }
  );
  const iconClasses = cx(`console-accordion-icon tw-transition-transform ${position}`, {
    'console-icon-open': open,
  });

  const contentClasses = cx('console-accordion-content', {
    'tw-overflow-hidden tw-max-h-0 tw-opacity-0 tw-invisible': !open,
    'tw-opacity-100 tw-visbile': open,
  });
  const handleOpen = (e: React.MouseEvent) => {
    e.preventDefault();
    if (!disabled) onOpen();
  };
  return (
    <div className={accordionClasses} {...rest} aria-disabled={disabled}>
      <button
        className={titleClasses}
        onClick={handleOpen}
        tabIndex={0}
        onMouseDown={e => e.preventDefault()}
        data-testid={dataTestId}
        aria-expanded={open}
      >
        {position === 'left' && (
          <Icon
            className={iconClasses}
            name="ChevronDownIconOutline"
            size="regular"
            aria-label="Open accordion"
          />
        )}
        {header}
        {position === 'right' && (
          <Icon
            className={iconClasses}
            name="ChevronDownIconOutline"
            size="regular"
            aria-label="Open accordion"
          />
        )}
      </button>
      <div className={contentClasses} style={open ? { maxHeight: 10000 } : {}} aria-hidden={!open}>
        {children}
      </div>
    </div>
  );
};
