import { useState, useEffect } from 'react';

import { SessionStore, NavigationStore } from 'state';
import globals from 'browser/globals';
import localStorage from 'browser/localStorage';
import UserDetailsResource from 'remote/resources/user-details';
import FeatureToggleResource from 'remote/resources/feature-toggles';
import NamespacesResource from 'remote/resources/namespaces';
import Actions from 'actions';
import logger from 'logger';
import { TenantNotFoundError, TenantWrongType } from 'actions/tenant-actions';
import { TenantSummary } from 'entities/tenant';
import { ApiClientRequestError, NetworkError } from 'remote/api-client/api-client-error';
import { ErrorReasons } from 'remote/error-handler/error-reason-codes';

import { useTenantsQuery } from 'remote/resources/tenants';
import { locationFromUrl } from 'state/navigation';
import {
  formatUpxDomain,
  parseMarketplaceQueryParameters,
  setMarketplaceQueryParameters,
} from 'components/utils';
import { useDefaultErrorHandler } from 'remote/error-handler';
import { parse } from 'query-string';
import { stripQueryParam } from 'actions/helpers';

export const useDataFetching = () => {
  const defaultErrorHandler = useDefaultErrorHandler(true);
  const [loading, setLoading] = useState(true);

  // START ERROR HANDLING CODE
  //
  // The following code is a bit funky, because
  // we want unhandled data fetcher errors to be tracked by
  // sentry. Because the useEffect has an async function
  // inside of it, when it throws the error it will
  // not propagate to the error boundary. The solution
  // is to store the error and then rethrow it
  // on rerender
  // https://stackoverflow.com/questions/67889383/react-error-boundaries-with-useeffect-and-async-function-what-im-missing
  //
  // We achieve this by:
  // 1. useDefaultErrorHandler with propagate = true so all non redirect errors will propagate
  // 2. handleError taking all unhandled errors from the useEffect and trying to use the default
  //    handler, falling back to setting the load error if default handling fails (ie a 500)
  // 3. Throw error if loadError has a value
  const [loadError, setLoadError] = useState(null);

  if (loadError) {
    throw loadError;
  }

  const handleError = async e => {
    try {
      await defaultErrorHandler(e);
    } catch {
      // Ignore bad network connection errors
      if (e instanceof NetworkError) {
        return;
      }
      setLoadError(e);
    }
  };
  // END ERROR HANDLING CODE

  useTenantsQuery();

  useEffect(() => {
    let componentWillUnmount = false;

    async function fetchEverything() {
      try {
        const userDetails = await UserDetailsResource.get();

        SessionStore.setUserDetails({
          userId: userDetails.UserId,
          acceptedTsAndCs: userDetails.AcceptedTermsAndConditions,
          workspaceEnabled: userDetails.WorkspaceEnabled,
          auraApiEnabled: userDetails.AuraApiEnabled,
          permissions: userDetails.Permissions,
          marketingChoiceRequired: userDetails.MarketingChoiceRequired,
        });

        const currentSsoOrgId = Actions.auth.getCurrentAuthenticatedSsoOrg();

        const currentTenant: TenantSummary = await Actions.tenants.findCurrentTenant(
          userDetails.UserId,
          SessionStore.state.partnerAccount,
          userDetails.PlanType,
          NavigationStore.state.location.search,
          userDetails.DefaultNamespace,
          currentSsoOrgId
        );

        if (currentTenant.ssoOrganizationId) {
          const needsLogin = await Actions.auth.authenticateWithSsoOrg(
            currentTenant.ssoOrganizationId
          );
          if (needsLogin) {
            return;
          }
        }

        const featureToggles = await FeatureToggleResource.get(currentTenant.id);

        const searchParams = parse(location.search);
        const marketplaceParams = parseMarketplaceQueryParameters();

        if (searchParams.upx === 'false' || location.host.startsWith('console-classic')) {
          localStorage.setItem(`upx-${userDetails.UserId}`, 'false');
          stripQueryParam(new URLSearchParams(location.search), 'upx');
        } else {
          let defaultUpx = localStorage.getItem(`upx-${userDetails.UserId}`);

          if (defaultUpx === null && featureToggles?.enable_default_upx_opt_in === true) {
            localStorage.setItem(`upx-${userDetails.UserId}`, 'true');
            defaultUpx = 'true';
          }

          if (
            defaultUpx === 'true' &&
            !location.hash.startsWith('#admin') &&
            marketplaceParams === null
          ) {
            const upxDomain = formatUpxDomain(
              process.env.ENVIRONMENT,
              process.env.NEO4J_DNS_DOMAIN
            );
            const upxUrl = `https://${upxDomain}/projects/${currentTenant.id}/instances`;
            global.location.replace(upxUrl);
            return;
          }
        }

        SessionStore.setCurrentTenant(currentTenant.id);
        SessionStore.setFeatureToggles(featureToggles ?? {});

        if (userDetails.AcceptedTermsAndConditions) {
          const { email, name, sub: auth0SubjectId } = SessionStore.state;
          Actions.userTracking.handleMixpanelIdentity({
            email,
            name,
            auth0SubjectId,
          });
        }
      } catch (error) {
        if (error instanceof ApiClientRequestError && error.reason === ErrorReasons.UNAUTHORIZED) {
          Actions.navigate.push({ hash: 'logout', search: { returnTo: 'approval-required' } });
          return;
        } else if (error instanceof TenantNotFoundError || error instanceof TenantWrongType) {
          SessionStore.setTenantNotFound();
          setLoading(false);
          return;
        }

        logger.error('Caught error while fetching user details:', error);
        handleError(error);
        return;
      }

      try {
        const [tenant, databaseSizes, invites] = await Promise.all([
          NamespacesResource.get(SessionStore.state.currentTenant).catch(err => {
            if (err instanceof ApiClientRequestError && [403, 404].includes(err.response.status)) {
              throw new TenantNotFoundError();
            } else {
              throw err;
            }
          }),
          Actions.databases.getAvailableSizes(),
          Actions.invites.getInvitesByUser(SessionStore.state.userId).catch(() => []),
        ]);

        SessionStore.setInvites(invites ?? []);
        SessionStore.setDatabaseSizes(databaseSizes);
        SessionStore.setTenant(tenant);

        const queryParams = globals.getUrlSearchParams();
        const defaultProduct = Actions.products.inferDefaultProduct(
          queryParams,
          localStorage,
          tenant.providerConfigs
        );
        Actions.products.setProduct(defaultProduct, { navigateReplace: true });

        setMarketplaceQueryParameters();

        if (Actions.marketplace.getMarketplaceRegistrationLocalStorage(SessionStore.state.email)) {
          const locationObject = locationFromUrl(location.href);
          locationObject.hash = 'marketplace-registration';
          Actions.navigate.replace({ ...locationObject });
        }

        if (!componentWillUnmount) {
          setLoading(false);
        }
      } catch (error) {
        if (error instanceof TenantNotFoundError) {
          SessionStore.setTenantNotFound();
          setLoading(false);
          return;
        }

        logger.error('Caught error while fetching session data:', error);
        handleError(error);
        return;
      }
    }

    fetchEverything();

    return () => {
      componentWillUnmount = true;
    };
  }, []);

  return { loading };
};
