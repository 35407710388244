import { CloudProvider, Region, Tier } from 'entities/database';
import { ActionStatus } from 'types/action-status';
import { RoleName } from 'types/user';

export enum PlanType {
  SELF_SERVE = 'self_serve',
  ENTERPRISE = 'enterprise',
}

export enum TenantType {
  PERSONAL = 'personal',
  N4GCP = 'n4gcp',
  MARKETPLACE_AWS = 'marketplace_aws',
  MARKETPLACE_AZURE = 'marketplace_azure',
  ENTERPRISE = 'enterprise',
}

export const tenantTypeDisplayName = (tenantType: TenantType): string => {
  switch (tenantType) {
    case TenantType.PERSONAL:
      return 'Self-Serve';
    case TenantType.N4GCP:
      return 'GCP Marketplace (N4GCP)';
    case TenantType.MARKETPLACE_AWS:
      return 'AWS Marketplace (via Tackle)';
    case TenantType.MARKETPLACE_AZURE:
      return 'Azure Marketplace (via Tackle)';
    case TenantType.ENTERPRISE:
      return 'Virtual Dedicated Cloud (formerly Enterprise)';
    default:
      return tenantType;
  }
};

export enum TenantProfile {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
}

export enum PricingPlanName {
  LEGACY_ENTERPRISE = 'legacy_enterprise',
  VIRTUAL_DEDICATED_CLOUD = 'virtual_dedicated_cloud',
}

export type ProviderConfigs = {
  [key in CloudProvider]?: ProviderConfig;
};

export type ProviderConfig = {
  tiers: ProviderConfigTiers;
};

export type ProviderConfigTiers = {
  [key in Tier]?: ProviderConfigTier;
};
export type ProviderConfigTier = {
  versions: string[];
  regions: Region[];
  default_version: string;
};

export type TierConfigsV2 = {
  [key in Tier]?: TierConfigV2;
};

type Regions = {
  [key in CloudProvider]?: Region;
};

export type TierConfigV2 = {
  cloud_provider_regions: Regions;
  default_version: string;
  tier: string;
  tier_display_name: string;
  versions: string[];
  secondary_cost?: string;
};

export function tierDisplayName(tenant: Tenant, tier: Tier): string {
  return (
    tenant.tierConfigs?.[tier]?.tier_display_name ?? tenant.unavailableTiers[tier].tier_display_name
  );
}

// Convert a long display name to an Initialism, e.g. "Business Critical" -> "BC"
export function tierDisplayNameInitials(tenant: Tenant, tier: Tier): string {
  const removeLowerCase = (word: string) =>
    word
      .split('')
      .filter(c => c === c.toUpperCase())
      .join('');

  return tierDisplayName(tenant, tier)
    .replace('Aura', '')
    .split(' ')
    .map(removeLowerCase)
    .join('');
}

export type TenantCapabilities = {
  cmek: boolean;
  log_forwarding: boolean;
  traffic_config: boolean;
  metrics_integration: boolean;
  secondaries: boolean;
  cdc_enrichment_mode: boolean;
  pro_trial: boolean;
  gds_sessions: boolean;
  multi_tenant_enterprise: boolean;
  consumption_reporting: boolean;
  gi_512_for_v4: boolean;
  vector_optimized: boolean;
  flexible_storage_options: boolean;
  custom_endpoints: boolean;
  azure_512_gi: boolean;
  gds_plugin: boolean;
};

export type AvailableTenantActions = {
  create_pro_trial: ActionStatus;
  extend_pro_trial: ActionStatus;
};

/** Used solely in admin UI */
export interface InternalTenant {
  id: string;
  friendlyName: string;
  internalName: string;
  tenantType: TenantType;
  planType: PlanType;
  suspended: boolean;
  suspensionReason?: string;
  isolationIds?: string[];
  organizationId?: string;
  mfaRequired: boolean;
  ssoConfigIds: string[];
  salesforceCppId: string | null;
  stripeUrl?: string;
  hasUnpaidInvoices?: boolean;
  tenantProfile: TenantProfile;
  billingMethod?: BillingMethod;
  pricingPlanName?: PricingPlanName;
  pricingPlanStartDate?: string;
}
/** Used solely in admin UI */
export interface IsolationUnit {
  id: string;
  legacy_id: string;
  cloud_provider: CloudProvider;
  tiers: Tier[];
}
export interface InternalTenantDetails extends Tenant, InternalTenant {
  isolationUnits?: IsolationUnit[];
  missingIsolationUnitIds?: string[];
}

interface TenantBase {
  name: string;
  id: string;
  planType: PlanType;
  tenantType: TenantType;
  suspended: boolean;
  hasBilling: boolean;
  requiresBilling: boolean;
  organizationId?: string;
  ssoOrganizationId?: string;
  billingMethod?: BillingMethod;
  /** googleProjectId is defined if tenantType is N4GCP  */
  googleProjectId?: string;
}

export enum TenantNotificationLevel {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success',
}

export type TenantNotificationAction = {
  label: string;
  actionId: string;
};

export type TenantNotificationDate = {
  name: string;
  format: string;
  timestamp: number;
};

export type TenantNotification = {
  level: TenantNotificationLevel;
  message: string;
  title: string;
  actions: TenantNotificationAction[];
  dismissalPeriod: number;
  dates: TenantNotificationDate[];
};

export interface Tenant extends TenantBase {
  hasUnpaidInvoices?: boolean;
  allowFreeDatabaseCreation: boolean;
  availableNeo4jVersions: string[];
  capabilities: TenantCapabilities;
  availableActions: AvailableTenantActions;
  providerConfigs: ProviderConfigs;
  tierConfigs: TierConfigsV2;
  unavailableTiers: TierConfigsV2;
  tenantProfile: TenantProfile;
  notifications: TenantNotification[];
  availableRoles: RoleName[];
}

export enum BillingMethod {
  PAYG = 'payg',
  PREPAID = 'prepaid',
  MARKETPLACE = 'marketplace',
}

export interface TenantSummary extends TenantBase {}
