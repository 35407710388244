import { useListByNamespace } from 'remote/resources/custom-endpoints';
import { useDatabaseQuery } from 'remote/resources/databases';
import { CustomEndpointsTable } from './table';
import React from 'react';
import { useSession } from 'store';
import { Page, Typography } from 'components/foundation';

const ComingSoon = () => {
  return (
    <div
      className="tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center tw-gap-16 tw-md:flex-row"
      data-testid="coming-soon"
    >
      <div className="tw-flex tw-max-w-[430px] tw-flex-col tw-gap-6">
        <h3>Coming soon...</h3>
        <Typography variant="body-large">
          This page will soon contain something exciting!
        </Typography>
      </div>
    </div>
  );
};

export const CustomEndpoints = () => {
  const { tenant } = useSession();
  const { data: endpoints = [], isLoading: endpointsLoading, refetch } = useListByNamespace(
    tenant.id,
    {
      skip: !tenant.capabilities.custom_endpoints,
    }
  );
  const { data: databases = [], isLoading: databasesLoading } = useDatabaseQuery(tenant.id, {
    skip: !tenant.capabilities.custom_endpoints,
  });

  if (tenant.capabilities.custom_endpoints) {
    return (
      <Page plain fullWidth>
        <div className="tw-mb-4">
          <Typography variant="h4">Custom Endpoints</Typography>
        </div>
        <CustomEndpointsTable
          databases={databases}
          endpoints={endpoints}
          isLoading={endpointsLoading || databasesLoading}
          refetch={refetch}
        />
      </Page>
    );
  } else {
    return <ComingSoon />;
  }
};
