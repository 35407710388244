import React, { useMemo, useState } from 'react';
import * as yup from 'yup';

import logger from 'logger';
import Actions from 'actions';
import { getErrorMessage } from 'remote/error-handler/error-messages';
import { validateYup, Validation } from 'utils/validation';
import { CreateDatabaseFormData, CreateDatabaseFormOptions } from './form-data';
import { EncryptionKeyRef, NEO4J_MANAGED_KEY } from 'types/encryption-keys';
import DatabaseSizeEstimator, {
  Data as SizeEstimatorData,
  defaults as sizeEstimatorDefaults,
  validate as validateSizeEstimatorData,
} from './form-size-estimator';
import SizeEstimateResult from './form-estimate-result';
import {
  DatabaseNameInput,
  RegionSelect,
  VersionSelect,
  EncryptionKeySelect,
} from './form-essentials';
import { SizeEstimate } from 'types/size-estimate';
import { findDatabaseSize, Tier } from 'entities/database';
import { formatSizeString, scrollToFirstError } from 'components/utils';
import track, { useTracking } from 'react-tracking';
import { ApiClientRequestError } from 'remote/api-client/api-client-error';
import { availableRegionsForTier, getCloudProvider } from 'entities/tenant';
import { useNeo4jVersionOptions, useLocation, useRegionOptions } from './hooks';
import { useSession } from 'store';
import { Divider, Button, Alert, Link } from 'foundation';
import { getDefaultVersion } from 'utils/neo4j-versions';

const schema = yup.object({
  name: DatabaseNameInput.yupValidator.label('Name'),
  version: yup
    .string()
    .required()
    .label('Version'),
  size: yup.object({ memory: yup.string().required() }).required(),
  vectorOptimized: yup.bool(),
  encryptionKeyRef: yup
    .string()
    .required()
    .label('Encryption Key'),
});

export const validate = (
  data: CreateDatabaseFormData,
  { onlyRequired }: { onlyRequired?: boolean } = {}
) => validateYup(schema, data, onlyRequired || false);

export const defaults = (options: CreateDatabaseFormOptions): CreateDatabaseFormData => {
  const availableRegions = availableRegionsForTier(options.providerConfigs, Tier.AURA_DSE);
  const region = availableRegions[0]?.name;
  const cloudProvider = getCloudProvider(options.providerConfigs);
  return {
    name: 'Instance01',
    region,
    version: getDefaultVersion(options.providerConfigs, cloudProvider, Tier.AURA_DSE),
    size: undefined,
    tier: Tier.AURA_DSE,
    cloudProvider,
    encryptionKeyRef: NEO4J_MANAGED_KEY,
    vectorOptimized: false,
  };
};

interface Props {
  data: CreateDatabaseFormData;
  options: CreateDatabaseFormOptions;
  onChange: (data: CreateDatabaseFormData) => any;
  validation: Validation<CreateDatabaseFormData>;
  disabled?: boolean;
  nodeCount?: number;
  relationshipCount?: number;
  cloning?: boolean;
}

const DseFields = ({
  data,
  options,
  onChange,
  validation,
  disabled,
  nodeCount,
  relationshipCount,
  cloning = false,
}: Props) => {
  const session = useSession();
  const showGDSVersionSelector = session.featureToggles.enable_gds_version_picker;
  const handleDatabaseNameChange = (name: string) => onChange({ ...data, name });
  const availableSizes = options.databaseSizes[Tier.AURA_DSE];
  const neo4jVersionOptions = useNeo4jVersionOptions(
    options.neo4jVersions,
    session.featureToggles['console-aura-latest']
  );

  const handleSizeChange = (memory: string) => {
    const exactMatch = findDatabaseSize(availableSizes, memory);
    const size = exactMatch || availableSizes[0];
    onChange({ ...data, size });
  };

  const [showCmekWarning, setShowCmekWarning] = useState(false);
  const [showNoCmeksAvailableWarning, setShowNoCmeksAvailableWarning] = useState(false);
  const handleEncryptionKeyChange = (encryptionKeyRef: EncryptionKeyRef) => {
    onChange({ ...data, encryptionKeyRef });
    setShowNoCmeksAvailableWarning(encryptionKeyRef === null);
    setShowCmekWarning(![NEO4J_MANAGED_KEY, null].includes(encryptionKeyRef));
  };

  const tracking = useTracking();

  const [sizeEstimate, setSizeEstimate] = useState<SizeEstimate>(null);
  const [estimateError, setEstimateError] = useState<string>(null);
  const [isCalculatingEstimate, setIsCalculatingEstimate] = useState(false);

  const initialSizeEstimation: SizeEstimatorData = sizeEstimatorDefaults(
    nodeCount,
    relationshipCount
  );
  const [sizeEstimatorData, setSizeEstimatorData] = useState(initialSizeEstimation);
  const [sizeEstimatorValidation, setSizeEstimatorValidation] = useState<
    Validation<SizeEstimatorData>
  >(null);
  const handleVersionChange = (version: string) => onChange({ ...data, version });

  const calculateEstimate = async e => {
    e.preventDefault();

    tracking.trackEvent({
      action: 'ESTIMATE_REQUEST',
      properties: {
        ...sizeEstimatorData,
      },
    });

    onChange({ ...data, size: undefined });
    const validationErrors = validateSizeEstimatorData(sizeEstimatorData);
    setSizeEstimatorValidation(validationErrors);
    setSizeEstimate(null);

    if (validationErrors) {
      scrollToFirstError();
      return;
    }

    setIsCalculatingEstimate(true);
    setEstimateError(null);

    try {
      const estimate = await Actions.estimations.estimateDatabaseSize(
        sizeEstimatorData.nodeCount > 1 ? sizeEstimatorData.nodeCount : 2,
        sizeEstimatorData.relationshipCount ?? 2,
        sizeEstimatorData.categories || [],
        data.tier
      );
      setSizeEstimate(estimate);
      handleSizeChange(estimate.recommended_size);
      setIsCalculatingEstimate(false);

      tracking.trackEvent({
        action: 'ESTIMATE_REQUEST_COMPLETE',
        properties: {
          ...sizeEstimatorData,
        },
      });
    } catch (err) {
      logger.error('Database size estimation request failed:', err);
      tracking.trackEvent({
        action: 'ESTIMATE_REQUEST_FAIL',
        properties: {
          ...sizeEstimatorData,
        },
      });
      if (err instanceof ApiClientRequestError) {
        setEstimateError(getErrorMessage(err.response.status, err.reason));
      } else {
        setEstimateError(getErrorMessage(500, null));
      }
      setIsCalculatingEstimate(false);
    }
  };

  const sizeEstimatorMissingRequiredFields = useMemo(
    () => !!validateSizeEstimatorData(sizeEstimatorData, { onlyRequired: true }),
    [sizeEstimatorData]
  );

  const cloudProvider = getCloudProvider(options.providerConfigs);
  const regions = availableRegionsForTier(options.providerConfigs, data.tier);
  const locationOptions = useLocation(regions);
  const regionOptions = useRegionOptions(regions);
  let label = 'Instance Location';
  let regionDropdownOptions = locationOptions;

  if (options.planType === 'enterprise') {
    label = 'Instance Region';
    regionDropdownOptions = regionOptions;
  }

  const handleRegionChange = (region: string) => {
    onChange({ ...data, region });
  };

  const handleSizeEstimatorDataChange = (newData: SizeEstimatorData) => {
    setSizeEstimatorData(newData);
  };

  const allowNeo4jManagedEncryptionKey = useMemo(() => {
    if (!options.tenant.capabilities.cmek) return true;
    if (!options.isCloned) return true;
    const initialEncryptionKey = options.database?.EncryptionKey?.encryptionKeyRef;
    if (!initialEncryptionKey) return true;
    if (initialEncryptionKey === NEO4J_MANAGED_KEY) return true;
  }, [options.tenant.capabilities.cmek, options.isCloned, options.database?.EncryptionKey]);

  return (
    <>
      <div>
        Please tell us a bit more about your graph and the algorithms you want to use so we can
        provide you with an appropriate sized instance, that would match your workload and ensure a
        smooth and flawless experience.
      </div>

      <Divider hidden />

      <h4 className="tw-mb-4 tw-mr-2">Instance details</h4>

      {!showGDSVersionSelector && (
        <div>
          <Alert
            type="info"
            icon={true}
            description={`${
              cloning ? 'Cloned' : 'New'
            } AuraDS instances will create a version 5 Neo4j Database.`}
            className="tw-mb-6"
          />
        </div>
      )}

      <DatabaseNameInput
        value={data.name}
        validationError={validation?.name?.message}
        onChange={handleDatabaseNameChange}
        disabled={disabled}
        className="tw-mb-4"
      />

      <RegionSelect
        value={data.region}
        label={label}
        options={regionDropdownOptions}
        onChange={handleRegionChange}
        cloudProvider={cloudProvider}
        validationError={validation?.region?.message}
      />

      {options.tenant.capabilities.cmek && (
        <div className="encryption-settings tw-mt-4">
          <EncryptionKeySelect
            value={data.encryptionKeyRef}
            tier={data.tier}
            region={data.region}
            onChange={handleEncryptionKeyChange}
            validationError={validation?.encryptionKeyRef?.message}
            disabled={disabled}
            tenant={options.tenant}
            allowNeo4jManagedKey={allowNeo4jManagedEncryptionKey}
          />
          <p className="tw-font-light tw-text-xs tw-mt-2" data-testid="cmek-link">
            You can configure Customer Managed Keys <Link href="#customer-managed-keys">here</Link>.
          </p>
        </div>
      )}

      {showCmekWarning && (
        <Alert
          type="warning"
          icon={true}
          description="By encrypting this instance with a Customer Managed Key, you take full responsibility for the management and security of the key. Neo4j cannot recover your data if the key is lost or invalid. There is a risk of permanent data loss."
          className="tw-my-6"
        />
      )}

      {showNoCmeksAvailableWarning && (
        <Alert
          type="warning"
          icon={true}
          description="Cloning to the selected region is not possible. There are no Customer Managed Keys in the selected region and cloning an encrypted instance to an instance with Neo4j Managed Key is not possible."
          className="tw-my-6"
        />
      )}

      {showGDSVersionSelector && (
        <div className="tw-mt-4">
          <VersionSelect
            value={data.version}
            options={neo4jVersionOptions}
            onChange={handleVersionChange}
            validationError={validation?.version?.message}
          />
        </div>
      )}

      <DatabaseSizeEstimator
        data={sizeEstimatorData}
        validation={sizeEstimatorValidation}
        onChange={handleSizeEstimatorDataChange}
      />

      {estimateError && (
        <Alert type="danger" data-testid="estimation-error" className="tw-mt-2">
          {estimateError}
        </Alert>
      )}

      <Divider hidden />

      <div className="tw-mx-auto tw-w-fit">
        <Button
          onClick={calculateEstimate}
          disabled={sizeEstimatorMissingRequiredFields || isCalculatingEstimate}
          loading={isCalculatingEstimate}
          data-testid="button-estimate"
        >
          {isCalculatingEstimate ? 'Calculating...' : 'Calculate Estimate'}
        </Button>
      </div>

      {data.size && (
        <>
          <Divider />
          <SizeEstimateResult size={data.size} tier={data.tier} />
        </>
      )}

      {sizeEstimate?.did_exceed_maximum && (
        <Alert type="warning" data-testid="did-exceed-max-msg" className="tw-mt-2">
          <p>
            One or more of your chosen algorithms may need &gt;
            {formatSizeString(sizeEstimate.recommended_size)} RAM to run efficiently.
          </p>
          <p>
            You can proceed with the maximum size available and{' '}
            <a href="mailto:cloudsales@neo4j.com">contact us</a> to discuss your requirements.
          </p>
        </Alert>
      )}
    </>
  );
};

export default track()(DseFields);
