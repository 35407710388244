import { Alert, Button, Checkbox, Dialog, TextInput, Tip } from 'components/foundation';
import { customEndpointURI } from 'entities/custom-endpoints';
import { Database } from 'entities/database';
import React, { useState, SyntheticEvent } from 'react';
import { CustomEndpoint } from 'types/custom-endpoints';

import customEndpointResources from 'remote/resources/custom-endpoints';

import logger from 'logger';
import { ApiClientRequestError } from 'remote/api-client/api-client-error';

type Props = {
  onClose: () => void;
  database?: Database;
  sourceDatabase: Database;
  endpoint: CustomEndpoint;
  refetch: () => void;
};

export const UndoTransferDialog = ({
  onClose,
  database,
  sourceDatabase,
  endpoint,
  refetch,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [hasConfirmed, setHasConfirmed] = useState(false);

  const handleConfirm = (checked: boolean) => {
    setHasConfirmed(checked);
  };

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();

    setErrorMessage(null);
    setIsLoading(true);

    customEndpointResources
      .update(endpoint.id, sourceDatabase.DbId)
      .then(() => {
        refetch();
        onClose();
      })
      .catch((error: ApiClientRequestError) => {
        setErrorMessage(error.message);
        logger.error(error.message);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Dialog open onClose={onClose}>
      <Dialog.Header>Undo custom endpoint transfer</Dialog.Header>
      <form onSubmit={handleSubmit}>
        <Dialog.Content className="flex flex-col gap-5">
          <div className="flex flex-col gap-4">
            <TextInput label="Custom endpoint" value={customEndpointURI(endpoint)} readOnly fluid />
            {database && (
              <TextInput label="Assigned instance" value={database.Name} readOnly fluid />
            )}
          </div>
          <Alert
            icon
            type="info"
            description="It will take a short moment for this custom endpoint to be ready for use again."
          />
          <Alert
            icon
            type="warning"
            description={
              <span>
                This action will assign this custom endpoint back to instance{' '}
                <b>{sourceDatabase.Name}</b>. You will not be able to configure this custom endpoint
                for the next 3 hours.
              </span>
            }
          />
          {errorMessage && <Alert icon type="danger" description={errorMessage} />}
        </Dialog.Content>
        <Dialog.Actions className="flex items-center justify-between">
          <Checkbox
            checked={hasConfirmed}
            onChange={event => handleConfirm(event.target.checked)}
            label="I understand"
          />
          <div className="flex gap-4 ">
            <Button color="neutral" fill="outlined" onClick={onClose}>
              Cancel
            </Button>
            <Tip type="tooltip" isDisabled={hasConfirmed}>
              <Tip.Trigger hasButtonWrapper>
                <Button color="primary" type="submit" loading={isLoading} disabled={!hasConfirmed}>
                  Confirm
                </Button>
              </Tip.Trigger>
              <Tip.Content className="!tw-fixed !tw-w-[200px]" isPortaled={false}>
                You need to confirm that you understand the consequences of undoing a transfer
              </Tip.Content>
            </Tip>
          </div>
        </Dialog.Actions>
      </form>
    </Dialog>
  );
};
