import React, { useEffect, useMemo } from 'react';
import { FormInput, FormSelect } from 'foundation/form-element';
import {
  CloudProvider,
  Tier,
  friendlyCloudProviderNameMap,
  DATABASE_NAME_CHARACTER_LIMIT,
} from 'entities/database';
import { Tenant } from 'entities/tenant';
import { useCmeks } from 'remote/resources/encryption-keys';
import {
  EncryptionKeyRef,
  NEO4J_MANAGED_KEY,
  NEO4J_MANAGED_KEY_LABEL,
  EncryptionKeyStatus,
} from 'types/encryption-keys';
import * as yup from 'yup';

export interface SelectOption {
  key: string;
  label: string;
  value: string;
}

interface DatabaseNameInputProps extends Omit<React.ComponentProps<typeof FormInput>, 'onChange'> {
  onChange: (value: string) => any;
  disabled?: boolean;
  validationError?: string;
}

export const DatabaseNameInput = ({
  onChange,
  disabled,
  value,
  validationError,
  ...rest
}: DatabaseNameInputProps) => {
  return (
    <FormInput
      fluid
      disabled={disabled}
      label="Instance Name"
      value={value}
      onChange={e => onChange(e.target.value)}
      errorText={validationError}
      data-testid="editable-title"
      {...rest}
    />
  );
};

// eslint-disable-next-line no-control-regex
const NO_CONTROL_CHARACTERS = /^[^\x00-\x1F\x7F]*$/;

DatabaseNameInput.yupValidator = yup
  .string()
  .required()
  .max(DATABASE_NAME_CHARACTER_LIMIT, 'Instance name must be at most 30 characters')
  .matches(NO_CONTROL_CHARACTERS, 'Instance name cannot contain control characters');

interface RegionSelectProps {
  value: string;
  options: SelectOption[];
  onChange: (value: string) => any;
  cloudProvider: CloudProvider;
  disabled?: boolean;
  validationError?: string;
  label?: string;
  placeholder?: string;
  styles?: any;
}

export const RegionSelect = ({
  disabled,
  value,
  options,
  onChange,
  cloudProvider,
  validationError,
  label,
  placeholder,
  styles,
}: RegionSelectProps) => {
  const csp = friendlyCloudProviderNameMap[cloudProvider];

  const chosenOption = options.find(option => option.value === value);
  return (
    <FormSelect
      disabled={disabled}
      fluid
      label={label ?? `${csp ?? ''} Region`}
      data-testid="region-picker"
      data-appcues-id="region-picker"
      errorText={validationError}
      onChange={e => onChange(e.value)}
      value={chosenOption}
      options={options}
      styles={styles}
      placeholder={placeholder || 'Select...'}
    />
  );
};

interface VersionSelectProps {
  value: string;
  options: SelectOption[];
  onChange: (value: string) => any;
  disabled?: boolean;
  validationError?: string;
}

export const VersionSelect = ({
  disabled,
  value,
  options,
  onChange,
  validationError,
}: VersionSelectProps) => {
  const chosenOption = options.find(option => option.value === value);
  return (
    <div>
      {options.length > 1 && (
        <FormSelect
          disabled={disabled}
          fluid
          label="Neo4j Version"
          data-testid="version-picker"
          errorText={validationError}
          onChange={e => onChange(e.value)}
          value={chosenOption}
          options={options}
        />
      )}
    </div>
  );
};

interface EncryptionKeySelectProps {
  value: EncryptionKeyRef;
  tier: Tier;
  region: string;
  onChange: (value: EncryptionKeyRef) => any;
  disabled?: boolean;
  validationError?: string;
  tenant: Tenant;
  allowNeo4jManagedKey?: boolean;
}

export const EncryptionKeySelect = ({
  value,
  tier,
  region,
  onChange,
  disabled,
  validationError,
  tenant,
  allowNeo4jManagedKey,
}: EncryptionKeySelectProps) => {
  const cmeksQuery = useCmeks(tenant);
  const customerManagedKeyOptions = useMemo(() => {
    if (!cmeksQuery.isSuccess) return [];
    return cmeksQuery.data
      .filter(k => k.status === EncryptionKeyStatus.READY)
      .filter(k => k.tier === tier && k.region === region)
      .map(
        (key): SelectOption => ({
          key: key.encryptionKeyRef,
          label: key.name,
          value: key.encryptionKeyRef,
        })
      );
  }, [cmeksQuery?.data, cmeksQuery.isSuccess, tier, region]);

  const keyOptions = useMemo(() => {
    const options = [...customerManagedKeyOptions];
    if (allowNeo4jManagedKey) {
      options.unshift({
        key: 'neo4j-managed-option',
        label: NEO4J_MANAGED_KEY_LABEL,
        value: NEO4J_MANAGED_KEY,
      });
    }
    return options;
  }, [allowNeo4jManagedKey, customerManagedKeyOptions]);

  const loadError: string | null = cmeksQuery.isError
    ? 'Failed to load encryption keys. ' +
      'Try refreshing the page, or contact support if the problem persists.'
    : null;

  const localValue = useMemo(() => {
    if (keyOptions.find(k => k.value === value)) {
      onChange(value);
      return value;
    }
    if (keyOptions.length > 0) {
      const value = keyOptions[0].value;
      onChange(value);
      return value;
    }
    return null;
  }, [keyOptions, value]);

  useEffect(() => {
    onChange(localValue);
  }, [localValue]);

  return (
    <FormSelect
      disabled={disabled}
      fluid
      loading={cmeksQuery.isLoading}
      label="Encryption Settings"
      data-testid="encryption-key-dropdown"
      data-appcues-id="encryption-key-dropdown"
      errorText={validationError || loadError}
      onChange={e => onChange(e.value as EncryptionKeyRef)}
      value={localValue}
      options={keyOptions}
      placeholder={'Select...'}
    />
  );
};
