import { MagnifyingGlassIconOutline } from '@neo4j-ndl/react/icons';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { PermissionTip } from 'components/application/permission-tip';
import {
  StickyActionsDataGrid,
  useDefaultTable,
  Button,
  IconButton,
  Label,
  TextInput,
  Tip,
  CopyTextToClipBoardButton,
  LoadingSpinner,
} from 'components/foundation';
import { customEndpointURI, friendlyCustomEndpointStatus } from 'entities/custom-endpoints';
import { Database } from 'entities/database';
import React, { useMemo, useState } from 'react';
import { usePermissions, useSession } from 'store';
import {
  CustomEndpoint,
  CustomEndpointDetails,
  CustomEndpointStatus,
} from 'types/custom-endpoints';
import { Action } from 'types/user';
import { DeleteCustomEndpointDialog } from './dialogs/delete';
import { ConfigureCustomEndpointDialog } from './dialogs/configure';
import { CreateCustomEndpointDialog } from './dialogs/create';
import { UndoTransferDialog } from './dialogs/undo-transfer';

type CustomEndpointAction = {
  action: 'configure' | 'undo-transfer' | 'delete';
  customEndpoint: CustomEndpoint;
  database?: Database;
  sourceDatabase?: Database;
};

const columnHelper = createColumnHelper<CustomEndpointDetails>();

const ActionRow = <T, U>({
  endpoint,
  database,
  sourceDatabase,
  setCurrentAction,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  cx,
}: {
  endpoint: CustomEndpointDetails;
  database?: Database;
  sourceDatabase?: Database;
  databases: Database[];
  setCurrentAction: (action: CustomEndpointAction) => void;
  cx: CellContext<T, U>;
}) => {
  const { allow } = usePermissions();
  const canEditEndpoint = allow(
    Action.UPDATE,
    `namespaces/${endpoint.namespaceId}/custom-endpoints/${endpoint.id}`
  );
  const canDeleteEndpoint = allow(
    Action.DELETE,
    `namespaces/${endpoint.namespaceId}/custom-endpoints/${endpoint.id}`
  );

  return (
    <>
      <div className="tw-flex tw-gap-2">
        {endpoint.isRevertible && sourceDatabase ? (
          <Tip
            isDisabled={
              canEditEndpoint && sourceDatabase.AvailableActions.link_custom_endpoint.enabled
            }
          >
            <Tip.Trigger>
              <Button
                size="small"
                fill="outlined"
                color="warning"
                disabled={
                  !canEditEndpoint || !sourceDatabase.AvailableActions.link_custom_endpoint.enabled
                }
                onClick={() =>
                  setCurrentAction({
                    action: 'undo-transfer',
                    customEndpoint: endpoint,
                    database: database,
                    sourceDatabase: sourceDatabase,
                  })
                }
                data-testid={`undo-transfer-${endpoint.id}`}
              >
                Undo transfer
              </Button>
            </Tip.Trigger>
            <Tip.Content>
              <Tip.Body>
                {canEditEndpoint ? (
                  <span>
                    {/* Hand-crafted message because it may not be clear which database
                        link_custom_endpoint.message is referring to */}
                    The previously assigned instance <b>{sourceDatabase.Name}</b> is currently not
                    available for custom endpoint assignment.
                  </span>
                ) : (
                  "You don't have permission to perform this action."
                )}
              </Tip.Body>
            </Tip.Content>
          </Tip>
        ) : (
          <Tip isDisabled={canEditEndpoint && endpoint.isTransferable}>
            <Tip.Trigger>
              <Button
                size="small"
                fill="outlined"
                color="neutral"
                disabled={!canEditEndpoint || !endpoint.isTransferable}
                onClick={() =>
                  setCurrentAction({
                    action: 'configure',
                    customEndpoint: endpoint,
                    database: database,
                  })
                }
                data-testid={`configure-${endpoint.id}`}
              >
                Configure
              </Button>
            </Tip.Trigger>
            <Tip.Content>
              <Tip.Body>
                {canEditEndpoint ? (
                  <span>
                    Custom endpoint <b>{endpoint.name}</b> was updated or created too recently.
                  </span>
                ) : (
                  'You don&apos;t have permission to perform this action.'
                )}
              </Tip.Body>
            </Tip.Content>
          </Tip>
        )}
        <PermissionTip hasPermission={canDeleteEndpoint}>
          <IconButton
            aria-label={`Delete custom endpoint ${endpoint.name}`}
            clean
            danger
            disabled={!canDeleteEndpoint}
            onClick={() =>
              setCurrentAction({
                action: 'delete',
                customEndpoint: endpoint,
                database: database,
              })
            }
            size="small"
            data-testid={`delete-${endpoint.id}`}
            iconName="TrashIconOutline"
          />
        </PermissionTip>
      </div>
    </>
  );
};

type Props = {
  databases: Database[];
  endpoints: CustomEndpointDetails[];
  isLoading: boolean;
  refetch: () => void;
};

export const CustomEndpointsTable = ({ databases, endpoints, isLoading, refetch }: Props) => {
  const [globalFilter, setGlobalFilter] = useState('');
  const { tenant } = useSession();
  const { allow } = usePermissions();
  const [openCreate, setOpenCreate] = useState(false);
  const [currentAction, setCurrentAction] = useState<CustomEndpointAction | null>(null);
  const isAllowedToCreateCustomEndpoint = allow(
    Action.CREATE,
    `namespaces/${tenant.id}/custom-endpoints`
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: () => 'URL',
        cell: cx => {
          const url = customEndpointURI(cx.row.original);
          return (
            <div className="tw-flex tw-items-center tw-gap-1 tw-truncate">
              <div className="tw-text-ellipsis tw-overflow-hidden">{url}</div>
              <CopyTextToClipBoardButton text={url} />
            </div>
          );
        },
        size: 450,
      }),
      columnHelper.accessor('dbId', {
        header: () => 'Instance',
        cell: cx => <>{databases.find(database => database.DbId === cx.getValue())?.Name}</>,
        maxSize: 250,
      }),
      columnHelper.accessor('status', {
        header: () => 'Status',
        cell: cx => {
          const status = cx.getValue();

          let color;
          switch (status) {
            case CustomEndpointStatus.ACTIVE:
              color = 'success';
              break;
            case CustomEndpointStatus.DELETED:
              color = 'default';
              break;
            case CustomEndpointStatus.ERROR:
              color = 'danger';
              break;
            case CustomEndpointStatus.DISCONNECTED:
              color = 'info';
              break;
            case CustomEndpointStatus.SUBMITTED:
              return (
                <div
                  className="tw-flex tw-flex-row tw-gap-1"
                  data-testid={`loading-status-${cx.row.original.id}`}
                >
                  <LoadingSpinner className="tw-self-center" /> <p>Loading...</p>
                </div>
              );
            case CustomEndpointStatus.UNKNOWN:
              color = 'warning';
              break;
          }

          return (
            <Label color={color} fill="semi-filled" data-testid={`status-${cx.row.original.id}`}>
              {friendlyCustomEndpointStatus(status)}
            </Label>
          );
        },
      }),
      columnHelper.display({
        id: 'hidden_column',
        header: () => null,
        enableResizing: false,
      }),
      columnHelper.display({
        id: 'actions',
        cell: cx => {
          const endpoint = cx.row.original;
          const database = databases.find(inst => inst.DbId === endpoint.dbId);
          const sourceDatabase = databases.find(inst => inst.DbId === endpoint.sourceDbId);

          if (endpoint.status === CustomEndpointStatus.DELETED) {
            return <></>;
          }

          return (
            <ActionRow
              endpoint={endpoint}
              database={database}
              sourceDatabase={sourceDatabase}
              databases={databases}
              setCurrentAction={setCurrentAction}
              cx={cx}
            />
          );
        },
        size: 250,
        enableResizing: false,

        meta: {
          isStickyAction: true,
        },
      }),
    ],
    [databases]
  );

  const filteredData = useMemo(
    () =>
      endpoints.filter(endpoint => {
        const url = customEndpointURI(endpoint);
        const database = databases.find(db => db.DbId === endpoint.dbId);
        const items = [url, friendlyCustomEndpointStatus(endpoint.status)];
        if (endpoint.dbId) {
          items.push(endpoint.dbId);
        }
        if (database) {
          items.push(database.Name);
        }
        return items.some(str => str.toLocaleLowerCase().includes(globalFilter));
      }),
    [databases, endpoints, globalFilter]
  );

  const table = useDefaultTable({
    columns,
    data: filteredData,
    initialState: {
      pagination: { pageSize: 25 },
    },
  });

  return (
    <>
      <div className="tw-p-8 tw-rounded-lg tw-bg-palette-neutral-bg-weak">
        <section className="tw-mb-6">
          <div className="tw-flex tw-flex-row tw-justify-between">
            <TextInput
              fluid
              className="tw-min-w-36"
              placeholder="Search"
              leftIcon={<MagnifyingGlassIconOutline />}
              value={globalFilter}
              onChange={event => setGlobalFilter(event.target.value)}
              aria-label="Search for custom endpoint"
            />
            <PermissionTip hasPermission={isAllowedToCreateCustomEndpoint}>
              <Button
                disabled={!isAllowedToCreateCustomEndpoint}
                data-testid="create-custom-endpoint-btn"
                onClick={() => setOpenCreate(true)}
              >
                Create custom endpoint
              </Button>
            </PermissionTip>
          </div>
        </section>

        <StickyActionsDataGrid
          isLoading={isLoading}
          tableInstance={table}
          styling={{
            headerStyle: 'clean',
            borderStyle: 'horizontal',
          }}
        />
      </div>
      {openCreate && (
        <CreateCustomEndpointDialog onClose={() => setOpenCreate(false)} databases={databases} />
      )}
      {currentAction?.action === 'delete' && (
        <DeleteCustomEndpointDialog
          endpoint={currentAction.customEndpoint}
          database={currentAction.database}
          onClose={() => setCurrentAction(null)}
          refetch={refetch}
        />
      )}
      {currentAction?.action === 'configure' && (
        <ConfigureCustomEndpointDialog
          endpoint={currentAction.customEndpoint}
          database={currentAction.database}
          databases={databases}
          onClose={() => setCurrentAction(null)}
          refetch={refetch}
        />
      )}
      {currentAction?.action === 'undo-transfer' && currentAction.sourceDatabase && (
        <UndoTransferDialog
          endpoint={currentAction.customEndpoint}
          database={currentAction.database}
          sourceDatabase={currentAction.sourceDatabase}
          onClose={() => setCurrentAction(null)}
          refetch={refetch}
        />
      )}
    </>
  );
};
